<template>
    <div class="Area">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>지역별 통계현황</h2>
                </div>
                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc">조회</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-profile">
                            <colgroup>
                                <col width="100">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                                <col width="50">
                            </colgroup>
                            <tbody>
                            <tr>
                                <th class="field text-center font-weight-bold">일자</th>
                                <th class="field text-center font-weight-bold">종로</th>
                                <th class="field text-center font-weight-bold">중구</th>
                                <th class="field text-center font-weight-bold">용산</th>
                                <th class="field text-center font-weight-bold">성동</th>
                                <th class="field text-center font-weight-bold">광진</th>
                                <th class="field text-center font-weight-bold">동대</th>
                                <th class="field text-center font-weight-bold">중랑</th>
                                <th class="field text-center font-weight-bold">성북</th>
                                <th class="field text-center font-weight-bold">강북</th>
                                <th class="field text-center font-weight-bold">도봉</th>
                                <th class="field text-center font-weight-bold">노원</th>
                                <th class="field text-center font-weight-bold">은평</th>
                                <th class="field text-center font-weight-bold">서대</th>
                                <th class="field text-center font-weight-bold">마포</th>
                                <th class="field text-center font-weight-bold">양천</th>
                                <th class="field text-center font-weight-bold">강서</th>
                                <th class="field text-center font-weight-bold">구로</th>
                                <th class="field text-center font-weight-bold">금천</th>
                                <th class="field text-center font-weight-bold">영등</th>
                                <th class="field text-center font-weight-bold">동작</th>
                                <th class="field text-center font-weight-bold">관악</th>
                                <th class="field text-center font-weight-bold">서초</th>
                                <th class="field text-center font-weight-bold">강남</th>
                                <th class="field text-center font-weight-bold">송파</th>
                                <th class="field text-center font-weight-bold">강동</th>
                                <th class="field text-center font-weight-bold">기타</th>
                                <th class="field text-center font-weight-bold">합계</th>
                            </tr>
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td class="text-center">{{ data.fregDate }}</td>
                                <td class="text-center text-link" @click="searchDetailFunc('종로구', data.fregDate, 'G1')">{{ data.jongnoCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('중구', data.fregDate, 'G2')">{{ data.jungguCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('용산구', data.fregDate, 'G3')">{{ data.yongsanCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('성동구', data.fregDate, 'G4')">{{ data.seongdongCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('광진구', data.fregDate, 'G5')">{{ data.gwangjinCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('동대문구', data.fregDate, 'G6')">{{ data.ddmCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('중랑구', data.fregDate, 'G7')">{{ data.jungnangCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('성북구', data.fregDate, 'G8')">{{ data.seongbukCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('강북구', data.fregDate, 'G9')">{{ data.gangbukCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('도봉구', data.fregDate, 'G10')">{{ data.dobongCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('노원구', data.fregDate, 'G11')">{{ data.nowonCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('은평구', data.fregDate, 'G12')">{{ data.epCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('서대문구', data.fregDate, 'G13')">{{ data.sdmCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('마포구', data.fregDate, 'G14')">{{ data.mapoCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('양천구', data.fregDate, 'G15')">{{ data.yangcheonCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('강서구', data.fregDate, 'G16')">{{ data.gangseoCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('구로구', data.fregDate, 'G17')">{{ data.guroCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('금천구', data.fregDate, 'G18')">{{ data.geumcheonCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('영등포구', data.fregDate, 'G19')">{{ data.ydpCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('동작구', data.fregDate, 'G20')">{{ data.dongjakCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('관악구', data.fregDate, 'G21')">{{ data.gwanakCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('서초구', data.fregDate, 'G22')">{{ data.seochoCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('강남구', data.fregDate, 'G23')">{{ data.gangnamCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('송파구', data.fregDate, 'G24')">{{ data.songpaCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('강동구', data.fregDate, 'G25')">{{ data.gangdongCnt | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('기타구', data.fregDate, 'G26')">{{ data.etcCnt | comma }} 명</td>
                                <td class="text-center text-link" >{{ data.totCnt | comma }} 명</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th class="field text-center font-weight-bold">총계</th>
                                <td class="text-center">{{ sum_G1 | comma }} 명</td>
                                <td class="text-center">{{ sum_G2 | comma }} 명</td>
                                <td class="text-center">{{ sum_G3 | comma }} 명</td>
                                <td class="text-center">{{ sum_G4 | comma }} 명</td>
                                <td class="text-center">{{ sum_G5 | comma }} 명</td>
                                <td class="text-center">{{ sum_G6 | comma }} 명</td>
                                <td class="text-center">{{ sum_G7 | comma }} 명</td>
                                <td class="text-center">{{ sum_G8 | comma }} 명</td>
                                <td class="text-center">{{ sum_G9 | comma }} 명</td>
                                <td class="text-center">{{ sum_G10 | comma }} 명</td>
                                <td class="text-center">{{ sum_G11 | comma }} 명</td>
                                <td class="text-center">{{ sum_G12 | comma }} 명</td>
                                <td class="text-center">{{ sum_G13 | comma }} 명</td>
                                <td class="text-center">{{ sum_G14 | comma }} 명</td>
                                <td class="text-center">{{ sum_G15 | comma }} 명</td>
                                <td class="text-center">{{ sum_G16 | comma }} 명</td>
                                <td class="text-center">{{ sum_G17 | comma }} 명</td>
                                <td class="text-center">{{ sum_G18 | comma }} 명</td>
                                <td class="text-center">{{ sum_G19 | comma }} 명</td>
                                <td class="text-center">{{ sum_G20 | comma }} 명</td>
                                <td class="text-center">{{ sum_G21 | comma }} 명</td>
                                <td class="text-center">{{ sum_G22 | comma }} 명</td>
                                <td class="text-center">{{ sum_G23 | comma }} 명</td>
                                <td class="text-center">{{ sum_G24 | comma }} 명</td>
                                <td class="text-center">{{ sum_G25 | comma }} 명</td>
                                <td class="text-center">{{ sum_G26 | comma }} 명</td>
                                <td class="text-center">{{ sum_G27 | comma }} 명</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Area',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),
            dataList : [],

            sum_G1 : 0,
            sum_G2 : 0,
            sum_G3 : 0,
            sum_G4 : 0,
            sum_G5 : 0,
            sum_G6 : 0,
            sum_G7 : 0,
            sum_G8 : 0,
            sum_G9 : 0,
            sum_G10 : 0,
            sum_G11 : 0,
            sum_G12 : 0,
            sum_G13 : 0,
            sum_G14 : 0,
            sum_G15 : 0,
            sum_G16 : 0,
            sum_G17 : 0,
            sum_G18 : 0,
            sum_G19 : 0,
            sum_G20 : 0,
            sum_G21 : 0,
            sum_G22 : 0,
            sum_G23 : 0,
            sum_G24 : 0,
            sum_G25 : 0,
            sum_G26 : 0,
            sum_G27 : 0,
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;

            this.searchListFunc()
        }else{
            this.searchListFunc()
        }
    },
    methods: {
        // 리스트 검색
        searchListFunc(){
            let params = `{"fromRegDt": "${this.dateToString(this.sch_date_start)}", "toRegDt": "${this.dateToString(this.sch_date_end)}"}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/region?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsRegion');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('f_admin_id', this.userDataAdminId);
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            let sum_G1 = 0
            let sum_G2 = 0
            let sum_G3 = 0
            let sum_G4 = 0
            let sum_G5 = 0
            let sum_G6 = 0
            let sum_G7 = 0
            let sum_G8 = 0
            let sum_G9 = 0
            let sum_G10 = 0
            let sum_G11 = 0
            let sum_G12 = 0
            let sum_G13 = 0
            let sum_G14 = 0
            let sum_G15 = 0
            let sum_G16 = 0
            let sum_G17 = 0
            let sum_G18 = 0
            let sum_G19 = 0
            let sum_G20 = 0
            let sum_G21 = 0
            let sum_G22 = 0
            let sum_G23 = 0
            let sum_G24 = 0
            let sum_G25 = 0
            let sum_G26 = 0
            let sum_G27 = 0

            result.list.forEach(function (item) {
                sum_G1 = parseInt(item.jongnoCnt) +  sum_G1;
                sum_G2 = parseInt(item.jungguCnt) +  sum_G2;
                sum_G3 = parseInt(item.yongsanCnt) +  sum_G3;
                sum_G4 = parseInt(item.seongdongCnt) +  sum_G4;
                sum_G5 = parseInt(item.gwangjinCnt) +  sum_G5;
                sum_G6 = parseInt(item.ddmCnt) +  sum_G6;
                sum_G7 = parseInt(item.jungnangCnt) +  sum_G7;
                sum_G8 = parseInt(item.seongbukCnt) +  sum_G8;
                sum_G9 = parseInt(item.gangbukCnt) +  sum_G9;
                sum_G10 = parseInt(item.dobongCnt) +  sum_G10;
                sum_G11 = parseInt(item.nowonCnt) +  sum_G11;
                sum_G12 = parseInt(item.epCnt) +  sum_G12;
                sum_G13 = parseInt(item.sdmCnt) +  sum_G13;
                sum_G14 = parseInt(item.mapoCnt) +  sum_G14;
                sum_G15 = parseInt(item.yangcheonCnt) +  sum_G15;
                sum_G16 = parseInt(item.gangseoCnt) +  sum_G16;
                sum_G17 = parseInt(item.guroCnt) +  sum_G17;
                sum_G18 = parseInt(item.geumcheonCnt) +  sum_G18;
                sum_G19 = parseInt(item.ydpCnt) +  sum_G19;
                sum_G20 = parseInt(item.dongjakCnt) +  sum_G20;
                sum_G21 = parseInt(item.gwanakCnt) +  sum_G21;
                sum_G22 = parseInt(item.seochoCnt) +  sum_G22;
                sum_G23 = parseInt(item.gangnamCnt) +  sum_G23;
                sum_G24 = parseInt(item.songpaCnt) +  sum_G24;
                sum_G25 = parseInt(item.gangdongCnt) +  sum_G25;
                sum_G26 = parseInt(item.etcCnt) +  sum_G26;
            });

            this.sum_G1 = sum_G1;
            this.sum_G2 = sum_G2;
            this.sum_G3 = sum_G3;
            this.sum_G4 = sum_G4;
            this.sum_G5 = sum_G5;
            this.sum_G6 = sum_G6;
            this.sum_G7 = sum_G7;
            this.sum_G8 = sum_G8;
            this.sum_G9 = sum_G9;
            this.sum_G10 = sum_G10;
            this.sum_G11 = sum_G11;
            this.sum_G12 = sum_G12;
            this.sum_G13 = sum_G13;
            this.sum_G14 = sum_G14;
            this.sum_G15 = sum_G15;
            this.sum_G16 = sum_G16;
            this.sum_G17 = sum_G17;
            this.sum_G18 = sum_G18;
            this.sum_G19 = sum_G19;
            this.sum_G20 = sum_G20;
            this.sum_G21 = sum_G21;
            this.sum_G22 = sum_G22;
            this.sum_G23 = sum_G23;
            this.sum_G24 = sum_G24;
            this.sum_G25 = sum_G25;
            this.sum_G26 = sum_G26;
            this.sum_G27 = sum_G1 + sum_G2 + sum_G3 + sum_G4 + sum_G5 + sum_G6 + sum_G7 + sum_G8 + sum_G9 + sum_G10 + sum_G11 + sum_G12 + sum_G13 + sum_G14 + sum_G15 + sum_G16 + sum_G17 + sum_G18 + sum_G19 + sum_G20 + sum_G21 + sum_G22 + sum_G23 + sum_G24 + sum_G25 + sum_G26;
        },
        excelDownFunc(){

            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_region.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }
            this.excelExport(url);
            */

            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}", 
                "toRegDt": "${this.dateToString(this.sch_date_end)}"
            }`;
            
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/region?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            let totalJongno = 0;
            let totalJunggu = 0;
            let totalYongsan = 0;
            let totalSeongdong = 0;
            let totalGwangjin = 0;
            let totalDdm = 0;
            let totalJungnang = 0;
            let totalSeongbuk = 0;
            let totalGangbuk = 0;
            let totalDobong = 0;
            let totalNowon = 0;
            let totalEp = 0;
            let totalSdm = 0;
            let totalMapo = 0;
            let totalYangcheon = 0;
            let totalGangseo = 0;
            let totalGuro = 0;
            let totalGeumcheon = 0;
            let totalYdp = 0;
            let totalDongjak = 0;
            let totalGwanak = 0;
            let totalSeocho = 0;
            let totalGangnam = 0;
            let totalSongpa = 0;
            let totalGangdong = 0;
            let totalEtc = 0;
            let totalSum = 0;

            // 엑셀 헤더 추가
            excelData.push(['일자', '종로구', '중구', '용산구',	'성동구', '광진구',	
            '동대문구', '중랑구', '성북구',	'강북구', '도봉구',	
            '노원구', '은평구',	'서대문구',	'마포구', '양천구',	
            '강서구', '구로구',	'금천구', '영등포구', '동작구',	
            '관악구', '서초구',	'강남구', '송파구',	'강동구', 
            '기타',	'합계']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                excelData.push([
                    data.fregDate, 
                    data.jongnoCnt, 
                    data.jungguCnt, 
                    data.yongsanCnt, 
                    data.seongdongCnt, 
                    data.gwangjinCnt, 
                    data.ddmCnt, 
                    data.jungnangCnt, 
                    data.seongbukCnt, 
                    data.gangbukCnt, 
                    data.dobongCnt, 
                    data.nowonCnt, 
                    data.epCnt, 
                    data.sdmCnt, 
                    data.mapoCnt, 
                    data.yangcheonCnt, 
                    data.gangseoCnt, 
                    data.guroCnt, 
                    data.geumcheonCnt, 
                    data.ydpCnt, 
                    data.dongjakCnt, 
                    data.gwanakCnt, 
                    data.seochoCnt, 
                    data.gangnamCnt, 
                    data.songpaCnt, 
                    data.gangdongCnt, 
                    data.etcCnt, 
                    data.totCnt 
                ]);

                // 각 지역별 데이터를 총계에 누적
                totalJongno += data.jongnoCnt;
                totalJunggu += data.jungguCnt;
                totalYongsan += data.yongsanCnt;
                totalSeongdong += data.seongdongCnt;
                totalGwangjin += data.gwangjinCnt;
                totalDdm += data.ddmCnt;
                totalJungnang += data.jungnangCnt;
                totalSeongbuk += data.seongbukCnt;
                totalGangbuk += data.gangbukCnt;
                totalDobong += data.dobongCnt;
                totalNowon += data.nowonCnt;
                totalEp += data.epCnt;
                totalSdm += data.sdmCnt;
                totalMapo += data.mapoCnt;
                totalYangcheon += data.yangcheonCnt;
                totalGangseo += data.gangseoCnt;
                totalGuro += data.guroCnt;
                totalGeumcheon += data.geumcheonCnt;
                totalYdp += data.ydpCnt;
                totalDongjak += data.dongjakCnt;
                totalGwanak += data.gwanakCnt;
                totalSeocho += data.seochoCnt;
                totalGangnam += data.gangnamCnt;
                totalSongpa += data.songpaCnt;
                totalGangdong += data.gangdongCnt;
                totalEtc += data.etcCnt; 
                totalSum += data.totCnt; 
            });

            // 총계 행 추가
            excelData.push(['총계', totalJongno, totalJunggu, totalYongsan, totalSeongdong, totalGwangjin, totalDdm, totalJungnang, totalSeongbuk, totalGangbuk, totalDobong, totalNowon, totalEp, totalSdm, totalMapo, totalYangcheon, totalGangseo, totalGuro, totalGeumcheon, totalYdp, totalDongjak, totalGwanak, totalSeocho, totalGangnam, totalSongpa, totalGangdong, totalEtc, totalSum]); 

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_지역별.xlsx');
        },

        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.title = title;
            data.date = date;
            data.idx = idx.startsWith("G") ? idx.substring(1) : idx;
            data.sch_type = 'region';
            data.sch_select_type = '';

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        },
    }
}
</script>

<style scoped>
</style>
